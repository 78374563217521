<template>
  <v-footer app color="background" class="py-4" :elevation="5">
    <v-container class="d-flex pa-0 text-body-1 justify-space-between reg-container">
      <hover-button @click="onBack" :disabled="backDisabled"> {{ $t('main.back') }}</hover-button>
      <v-spacer v-if="this.$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-tooltip top :disabled="!missingFields.length">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <hover-button @click="onNext" variant="true" :disabled="!completed">
              <span class="d-flex justify-center">
                <div v-if="!isNextButtonLoading">{{ nextButtonText }}</div>
                <div v-else><v-progress-circular indeterminate /></div>
              </span>
            </hover-button>
          </div>
        </template>
        <div class="d-flex flex-column">
          <div v-for="field in missingFields" :key="field.name">
            <v-icon class="mr-3" color="error">mdi-alert</v-icon
            ><span>{{ field.displayText }}</span>
          </div>
        </div>
      </v-tooltip>
    </v-container>
  </v-footer>
</template>
<style scoped>
.theme--light {
  opacity: 1 !important;
}
</style>
<script>
import HoverButton from './HoverButton.vue';

export default {
  name: 'RegisterFooter',
  data() {
    return {
      hasBeenClicked: false,
    };
  },
  components: { HoverButton },
  props: {
    backBtn: Boolean,
    backDisabled: Boolean,
    elevate: Boolean,
    nextBtn: Boolean,
    nextRoute: [Object, String],
    nextDisabled: Boolean,
    isPageflowV2: {
      required: false,
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    isBackDisabled() {
      return (
        this.$route.meta.backDisabled || !this.$store.getters.previousFlowRoute(this.$route.name)
      );
    },
    completed() {
      return this.$store.state.registration.stepCompleted;
    },
    isNextButtonLoading() {
      return this.nextButtonText === 'isLoading';
    },
    loading() {
      return this.$store.state.registration.loading;
    },
    nextButtonText() {
      return (
        this.$store.state.registration.currentPage?.options?.nextButtonText || this.$t('main.next')
      );
    },
    missingFields() {
      return this.$store.state.registration.remainingFields.filter((field) => !field.valid);
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
  },
  methods: {
    onBack() {
      if (this.isPageflowV2) {
        this.$emit('back');
      } else {
        this.$emit('back');
        this.$router.push({ name: this.$store.getters.previousFlowRoute(this.$route.name) });
      }
    },
    onNext() {
      if (!this.isPageflowV2) {
        this.$emit('next');
      }
      if (!this.hasBeenClicked) {
        this.$store.state.registration.onNextClick();
      }
      this.hasBeenClicked = true;
    },
  },
  watch: {
    completed(val) {
      if (!val) {
        this.hasBeenClicked = false;
      }
    },
  },
};
</script>
